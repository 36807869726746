import 'whatwg-fetch';
import Pristine from 'pristinejs';
console.log('VMG BLACK+');

// set application closing date.
const nowDate = new Date();
const estNowDate = changeTimeZone(nowDate, 'America/New_York');
const closingDate = new Date('July 8, 2022 23:59:59');
const estClosingDate = changeTimeZone(closingDate, 'America/New_York');

function changeTimeZone(date, timeZone) {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    })
  );
}

document.addEventListener('DOMContentLoaded', function () {
  const faqQuestions = document.querySelectorAll('[data-faq-question]');

  function toggleFaq(e) {
    const el = e.currentTarget;
    const faqNum = el.dataset.faqQuestion;
    const faqItem = document.querySelector(`[data-faq-item="${faqNum}"]`);

    if (faqItem.classList.contains('faq-item--active')) {
      faqItem.classList.remove('faq-item--active');
    } else {
      faqItem.classList.add('faq-item--active');
    }
  }

  for (let i = 0, len = faqQuestions.length; i < len; i++) {
    faqQuestions[i].addEventListener('click', toggleFaq);
  }

  function exposeFields(fieldName) {
    const fields = document.querySelectorAll(`input[name="${fieldName}"]`);

    function handleFieldClick(e) {
      const el = e.currentTarget;
      // const revealControl = document.querySelector(`[data-reveal="${fieldName}"]`);
      const elementToReveal = document.querySelector(
        `[data-reveal-if-active="${fieldName}"]`
      );

      if (!el.dataset.reveal) {
        // hide reveal element
        elementToReveal.style.display = 'none';
      } else {
        // show reveal element
        elementToReveal.style.display = 'block';
      }
    }

    for (let i = 0, len = fields.length; i < len; i++) {
      fields[i].addEventListener('click', handleFieldClick);
    }
  }

  exposeFields('business_stage');
  exposeFields('certifications');

  function setToViewportHeight() {
    const viewportHeights = document.querySelectorAll('.js-vp-full');

    for (let i = 0, len = viewportHeights.length; i < len; i++) {
      viewportHeights[i].style.height = `${window.innerHeight}px`;
    }
  }

  setToViewportHeight();
  window.addEventListener('resize', setToViewportHeight);
});

// google form
const FORM_ID = '1FAIpQLSf9NR5ByvGAWYgUqppgvBszyMAYIBIoJ73czfk2GDUj-J4GVw';
const FORM_URL =
  'https://docs.google.com/forms/d/e/' +
  FORM_ID +
  '/formResponse?submit=SUBMIT';
const FORM_FIELDS = {
  business_name: 'entry.1358388170',
  email: 'entry.1464489671',
  phone: 'entry.1309174991',
  social_handles: 'entry.1735345011',
  company_website: 'entry.2080685422',
  location_state: 'entry.851165977',
  location_city: 'entry.910542098',
  overview: 'entry.1607009629',
  promote: 'entry.1039480222',
  revenue: 'entry.990733679',
  business_stage: 'entry.268417492',
  business_stage_none: 'entry.2084776363',
  industry: 'entry.936075987',
  service_product: 'entry.704091768',
  why: 'entry.1467452191',
  marketing: 'entry.1817874744',
  target_audience: 'entry.1083297333',
  contribute: 'entry.709093987',
  one_question: 'entry.865884034',
  certifications: 'entry.2032812494',
  has_certifications: 'entry.772099012',
  assets: 'entry.1744580364',
  agree_to_terms: 'entry.1927702896',
  eligible: 'entry.326406323',
};

window.addEventListener('load', function () {
  // get form element
  const form = document.getElementById('form');
  const applicationClosed = document.getElementById('application-closed');
  const submitButton = document.getElementById('submit');
  const submitSuccess = document.getElementById('submit-success');
  const submitError = document.getElementById('submit-error');
  const formInvalid = document.getElementById('form-invalid');
  // create pristine instance for form validation.
  const pristine = new Pristine(form);

  function sendData() {
    // get data from Form
    const FD = new FormData(form);

    // extract FormData to an object, ie11 doesn't support FormData.get();
    const formData = {};
    for (var [key, value] of FD) {
      formData[key] = value;
    }

    // build params string with keys and values
    const paramsString = Object.entries(FORM_FIELDS).reduce(function (
      acc,
      curr
    ) {
      return (acc = acc + '&' + curr[1] + '=' + formData[curr[0]]);
    },
    '');

    // make paramsString url friendly, and replace spaces with '+'
    const encodedParamString = encodeURI(paramsString).replace(/%20/g, '+');

    const opts = {
      method: 'POST',
      mode: 'no-cors',
      redirect: 'follow',
      referrer: 'no-referrer',
    };

    return fetch(FORM_URL + encodedParamString, opts);
  }

  form.addEventListener('submit', function (event) {
    event.preventDefault();
    const valid = pristine.validate();

    if (!valid) {
      formInvalid.style.display = 'block';
      return;
    }

    // reset form-invalid message
    formInvalid.style.display = 'none';

    // disable submit button
    submitButton.disabled = true;
    submitButton.innerHTML = 'Submitting Application...';

    sendData()
      .then((response) => {
        console.log('response', response);
        submitButton.style.display = 'none';
        submitSuccess.style.display = 'block';
      })
      .catch((error) => {
        submitButton.disabled = false;
        submitButton.innerHTML = 'Apply';
        submitError.style.display = 'block';
        console.log('There was an error posting to the form', error);
      });
  });

  // close submissions
  if (estNowDate > estClosingDate) {
    applicationClosed.style.display = 'block';
    form.style.display = 'none';
  } else {
    applicationClosed.style.display = 'none';
    form.style.display = 'block';
  }
});
